const server = {
 // url: "https://blackbox.saehanel.co.kr",
 // url: "http://221.143.48.220:2308",
 // url: "http://localhost:24063",
 // url: "http://221.143.48.220:2301",
//  url: "http://apis.data.go.kr",
// url: "https://sangdo.saehanel.co.kr",
//url: "https://jung3.saehanel.co.kr",
// url: "https://jung4.saehanel.co.kr",
// url: "https://bondong.saehanel.co.kr",
url: "https://majang.saehanel.co.kr",
//name : "마장 SH빌"
name : "마장 SH빌"
// name : "중계 4단지"
};

export default server;


